
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import userInfo from "@/core/services/UserInfo";
import { useStore } from "vuex";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import { allPackages } from "@/store/pinia/AllPackages";
import { platforms } from "@/store/pinia/Platforms";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "FreeTestSubscription-kt",
  emit: ["returnBotId"],
  props: {
    classes: {
      type: String,
      default: "btn btn-lg btn-primary",
    },
    autoCreateBot: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const createdBotForTest = ref();

    const allBotsHolder = AllBots();
    const { fetchBotList } = allBotsHolder;

    const allPackagesHolder = allPackages();
    const { packageList, findFreePackageId } = storeToRefs(allPackagesHolder);
    const { fetchPackageList } = allPackagesHolder;

    const allPlatformsHolder = platforms();
    const { platformsList } = storeToRefs(allPlatformsHolder);

    const user = computed(() => {
      return userInfo.getUser();
    });

    const submitButton = ref<HTMLButtonElement | null>(null);

    // watch(()=> findFreePackageId)
    let activeSocialMediaKeys = ref([]) as any;
    for (let key in platformsList.value) {
      if (platformsList.value[key].status === true) {
        activeSocialMediaKeys.value.push(key);
      }
    }

    const sendParameter = computed(() => {
      return {
        bot_id: createdBotForTest.value?.bot_id,
        package_id: findFreePackageId.value,
        platforms: activeSocialMediaKeys.value,
      };
    });

    const saveBot = async () => {
      try {
        const result = await ApiService.post("bot/create", {
          name: translate("free test subscriptions"),
        });
        return result.data.data.bot;
      } catch (e) {
        if (e && e.response && e.response.data)
          store.commit(Mutations.SET_ERROR, e.response.data);
        if (submitButton.value)
          submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const router = useRouter();
    const payWithCredit = async () => {
      if (!findFreePackageId.value) {
        alert("متاسفانه شما قبلا از اشتراک تست رایگان استفاده کرده اید.");
        router.push('router.push("/bot-connections");');
      }

      // پرداخت با کیف پول
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      if (!createdBotForTest.value) {
        createdBotForTest.value = await saveBot();
      }

      try {
        await ApiService.post("bot/buy", sendParameter.value).then(
          () => {
            return;
          }
        );
        await fetchBotList();
        emit("returnBotId", createdBotForTest.value?.bot_id);
      } catch ({ response }) {
        if (response && response.data)
          store.commit(Mutations.SET_ERROR, response.data);
      } finally {
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };
    const bots = ref()

    onMounted(async () => {

      await ApiService.query("bot/all", {
        params: {
          with_platforms: true,
        with_user: true,
        expiration_type : '',
      },
    })
      .then(({ data }) => {
        bots.value = data.data.bots;
      })
    .catch(({ response }) => {
       console.log(response);
       
      })

      if (!packageList.value) {
        await fetchPackageList();
      }
      if (props.autoCreateBot === true) {
        payWithCredit();
      }
    });

    return {
      user,
      translate,
      submitButton,
      payWithCredit,
      findFreePackageId,
      sendParameter,
      bots,
    };
  },
});
