
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import FreeTestSubscription from "@/components/FreeTestSubscription.vue";
export default defineComponent({
  name: "dashboard",
  components: {
    FreeTestSubscription,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    return {
      translate,
    };
  },
});
