import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
export const allPackages = defineStore("allpackagesinfo", () => {
  const store = useStore();

  // state
  const packageList = ref();
  const tryCounter = ref(0);

  const findFreePackageId = computed(() => {
    if (packageList.value) {
      const find = packageList.value.find((item) => item.is_free === "yes");
      if (find) return find["id"];
    }
    return null;
  });

  // actions
  const fetchPackageList = async () => {
    try {
      const response = await ApiService.get("packages");
      packageList.value = response.data.data.packages;
      tryCounter.value = 0;
    } catch (error) {
      if (tryCounter.value < 3) {
        tryCounter.value++;
        await fetchPackageList();
      }
      if (error.response && error.response.data) {
        store.commit(Mutations.SET_ERROR, error.response.data);
      }
    }
  };

  return {
    packageList,
    fetchPackageList,
    findFreePackageId,
  };
});
